<template>
	<v-app style="no-repeat center center fixed !important; background-size: cover;" :style="{ 'background-image': 'linear-gradient(to right top, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.7)), url(' + image + ')' }">
		<!-- <v-img 
			:height="$vuetify.breakpoint.smAndDown? 'auto' : '100%'"
			:src="imageSrc" contain
			gradient="to top right, rgba(0,0,0,.33), rgba(0,0,0,.7)"
		> -->

			<!-- <v-container fill-height fluid> -->
				<!-- <gavcom-full-page-app-bar /> -->
				<v-container fill-height fluid>
					<gavcom-full-page-view />
				</v-container>	
				<gavcom-full-page-footer />
			<!-- </v-container> -->

		<!-- </v-img> -->
	</v-app>
</template>

<script>
export default {
  name: "GavcomFullPageIndex",

  components: {
    GavcomFullPageAppBar: () => import("@/layouts/gavcom/full_page/AppBar"),
		GavcomFullPageView: () => import("@/layouts/gavcom/full_page/View"),
		GavcomFullPageFooter: () => import("@/layouts/gavcom/full_page/Footer"),

		// BaseLoader: () => import("@/components/base/Loader"),
  },

  data: () => ({
		image: require("@/assets/landscape/Illimani_La_Paz_3.jpg"),
	}),
}
</script>

<style>
	/* .invisible-scrollbar::-webkit-scrollbar {
		display: none !important;
	} */

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 0px;
	}
</style>
